<template>
   <cui-acl :roles="['underDevelopment']" redirect>
      <div class="card">
      <div class="card-body">
        <h4>
          <span>Instillinger Tabeller</span>
          <a-button style="float: right;" @click="reports.push({ newReport: true , title: 'New Report', description: '' })">
            New Report
          </a-button>
        </h4>
        <a-collapse accordion>
          <a-collapse-panel :key="i" :header="report.title" v-for="(report, i) in reports">
            ID: {{ report.id }}
            <a-form @submit.prevent="editReportSettings(i)">
              <div class="row">
                <div class="col-md-3">
                  <a-form-item label="Title">
                    <a-input placeholder="title" v-model="report.title"/>
                  </a-form-item>
                </div>
                <div class="col-md-9">
                  <a-form-item label="Description">
                    <a-input placeholder="Description" v-model="report.description"/>
                  </a-form-item>
                </div>
                <div class="col-md-6">
                  <a-form-item label="Access">
                    <a-select
                      :filter-option="filterOption"
                      option-filter-prop="children"
                      v-model="report.access"
                      mode="multiple">
                      <a-select-option v-for="employee in employees" :key="employee.value" :value="employee.value">
                        {{ employee.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <a-form-item label="Type">
                    <a-select
                      option-filter-prop="children"
                      v-model="report.type"
                      >
                      <a-select-option v-for="reportType in reportTypes" :key="reportType.value" :value="reportType.value">
                        {{ reportType.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
                <div class="col-md-6">
                  <a-form-item label="Url">
                    <a-input placeholder="Report url" v-model="report.url"/>
                  </a-form-item>
                </div>
              </div>
              <a-button html-type="submit" type="primary" class="btn btn-light px-5">Lagre</a-button>
            </a-form>
            <a-button type="secondary" class="btn btn-light px-5" @click="deleteReport(i)">Slett</a-button>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import { openNotification } from '@/services/powerAutomate'

import { firestore } from '@/services/firebase'

const reportTypes = [
  {
    value: 'iframe',
    label: 'iFrame',
  },
  {
    value: 'url',
    label: 'Link to Report',
  },
]

export default {
  components: {
    cuiAcl,
  },
  data() {
    return {
      reports: [],
      reportTypes,
      employees: [],
      apikey: 'wwqUGqJc7XzBvK258ZBF5OTVyFo',
    }
  },
  methods: {
    deleteReport(i) {
      firestore.collection('reports').doc(this.reports[i].id).delete()
        .then(() => {
          openNotification('success', 'Report deleted')
          this.reports.splice(i, 1)
        })
    },
    editReportSettings(i) {
      const newData = this.reports[i]
      // replace all undifined with null
      Object.keys(newData).forEach(key => {
        if (newData[key] === undefined) {
          newData[key] = null
        }
      })
      if (newData.access === undefined) {
        newData.access = []
      }
      console.log(newData)
      if (!newData.newReport) {
        delete newData.newTable
        firestore.collection('reports').doc(this.reports[i].id).update(newData)
          .then(() => {
            openNotification('success', 'Report settings updated')
          })
      } else {
        console.log(newData)
        delete newData.newReport
        this.reports[i].id = firestore.collection('reports').add(newData)
          .then(() => {
            openNotification('success', 'Report settings updated')
          }).id
      }
      newData.access.forEach(employee => {
        this.checkEmployeeRole(employee)
      })
      console.log(this.reports)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    checkEmployeeRole(id) {
      firestore.collection('users').doc(id).get()
        .then(doc => {
          if (doc.data().roles.includes('reports')) {
            return true
          } else {
            // add supporttables as access to user
            firestore.collection('users').doc(id).update({
              roles: [...doc.data().roles, 'reports'],
            })
            return true
          }
        })
    },
  },
  mounted() {
    let ref = firestore.collection('reports')
    if (['underDevelopment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      firestore.collection('users').onSnapshot(
        snapshot => {
          this.users = []
          snapshot.forEach(doc => {
            this.employees.push({
              value: doc.id,
              label: doc.data().fullName,
            })
          })
        },
      )
    } else {
      ref = ref.where('access', 'array-contains', this.$store.state.user.id)
    }
    ref.onSnapshot(snapshot => {
      this.reports = []
      snapshot.forEach(doc => {
        this.reports.push({
          id: doc.id,
          title: doc.data().title,
          subtext: doc.data().description,
          description: doc.data().description,
          newReport: false,
          access: doc.data().access,
          url: doc.data().url,
          type: 'iframe',
        })
      })
    })
  },
}
</script>

<style>

</style>
